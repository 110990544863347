<template>
  <div id="Maintenance">
    <div class="infoImg">
      <div class="infoImg-cont">
        <img class="img-icon" src="@/assets/images/icons/icono-inhabilitado.svg">
        <span class="text-up">{{ $t('maintenanceView.text1') }}</span>
        <span class="text-down">{{ $t('maintenanceView.text2') }}</span>
      </div>

    </div>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  name: 'Maintenance',
  mounted () {
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  }
};
</script>
<style lang="scss">
  #Maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    background-image: url(../assets/images/maintenance.jpg);
    padding: 80px;
    .infoImg{
      display: flex;
      background-color: #fff;
      width: 550px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
      border-radius: 15px;
      &-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-icon{
          width: 30%;
        }
        .text-up{
          padding: 10px;
          font-size: 30px;
          color:#000;
          font-weight: bolder;
        }
        .text-down{
          color: #000;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          border-bottom: solid 1px #E6E6E6;
          padding: 10px;
          margin-bottom: 30px;
        }
      }
    }
  }
</style>
